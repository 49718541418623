iframe {
  width: 100% !important;
}

.remove-border-radius {
  border-radius: 0 !important;
}

code {
  border-radius: 2px;
}
