// Front Default Color Variables
// =========================================================

$root-path: "../";

// Grays
$gray-100: #f7faff;
$gray-200: #f8fafd;
$gray-300: #e7eaf3;
$gray-400: #bdc5d1;
$gray-500: #97a4af;
$gray-600: #8c98a4;
$gray-700: #677788;
$gray-800: #71869d;
$gray-900: #1e2022;

// Colors
$white: #fff;
$dark: #21325b;
$blue: #377dff;
$teal: #00c9a7;
$cyan: #09a5be;
$yellow: #f5ca99;
$red: #ed4c78;
$main: #142454;

// Theme Colors
$primary: $main;
$secondary: $gray-800;
$success: $teal;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$dark: $dark;

// Theme Soft Colors
$primary-soft: rgba($primary, 0.1);
$secondary-soft: rgba($secondary, 0.1);
$success-soft: rgba($success, 0.1);
$info-soft: rgba($info, 0.1);
$warning-soft: rgba($warning, 0.1);
$danger-soft: rgba($danger, 0.1);
$light-soft: rgba($gray-100, 0.1);
$dark-soft: rgba($dark, 0.1);

$primary-text-emphasis-dark: rgba($primary, 0.8);
$secondary-text-emphasis-dark: rgba($secondary, 0.8);
$success-text-emphasis-dark: rgba($success, 0.8);
$info-text-emphasis-dark: rgba($info, 0.8);
$warning-text-emphasis-dark: rgba($warning, 0.8);
$danger-text-emphasis-dark: rgba($danger, 0.8);
$light-text-emphasis-dark: rgba($gray-100, 0.8);
$dark-text-emphasis-dark: rgba($dark, 0.8);
$primary-bg-subtle-dark: rgba($primary, 0.15);
$secondary-bg-subtle-dark: rgba($secondary, 0.15);
$success-bg-subtle-dark: rgba($success, 0.15);
$info-bg-subtle-dark: rgba($info, 0.15);
$warning-bg-subtle-dark: rgba($warning, 0.15);
$danger-bg-subtle-dark: rgba($danger, 0.15);
$light-bg-subtle-dark: rgba($gray-100, 0.15);
$dark-bg-subtle-dark: rgba($dark, 0.15);
$primary-border-subtle-dark: rgba($primary, 0.2);
$secondary-border-subtle-dark: rgba($secondary, 0.2);
$success-border-subtle-dark: rgba($success, 0.2);
$info-border-subtle-dark: rgba($info, 0.2);
$warning-border-subtle-dark: rgba($warning, 0.2);
$danger-border-subtle-dark: rgba($danger, 0.2);
$light-border-subtle-dark: rgba($gray-100, 0.2);
$dark-border-subtle-dark: rgba($dark, 0.2);
$primary-text-subtle-dark: rgba($primary, 0.5);
$secondary-text-subtle-dark: rgba($secondary, 0.5);
$success-text-subtle-dark: rgba($success, 0.5);
$info-text-subtle-dark: rgba($info, 0.5);
