@import "../node_modules/bootstrap/scss/functions";

@import "scss/themes/default";
@import "scss/user-variables";
@import "scss/front/variables";

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/utilities";

@import "scss/front/utilities";
@import "scss/user-utilities";

@import "../node_modules/bootstrap/scss/bootstrap";

@import "scss/front/front";

@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import "scss/user";
